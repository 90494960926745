import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Testy McTestface
        </p>
        <code>
          <h1>Valuable Inventory: This is content</h1>
        <p>
        Unit testing code using jest is a simple and effective way to increase the code quality of your software.  We've also seen the use of jest in some applications where you can quickly test an application without having to write anything else.
        </p>
        <p>
Start by using yarn or npm to install jest and then open your editor to start coding. Make sure you have the necessary JUnit installed. Focus on testing small pieces of your application. You can start by building the editor using the following command:
</p>
        <p>
$ npm install jest
</p>
        <p>
Or if you prefer, this is the command for yarn:
</p>
        <p>
 $ yarn install jest
 </p>
        <p>
If your remote engineers are only booked at 90% capacity, ask them to write unit tests! They'll make you happy, but that doesn't guarantee that the software works. But it will increase code coverage.  Code coverage is  especially important when using jest. But sometimes less is more!  If you have less code, then you can have more coverage because  that code has some issues, which makes you better.
</p>
        <p>
After your unit tests are written, make sure to deliver them to the CI/CD system of your choice.  Maybe you like to use github actions?  They're great for  you!
</p>
        <p>
To get your code running, first log into the account and upload your private key.  Then make sure the information is complete.  Once the information is complete, start editing your code.
        </p>
        </code>
      </header>
    </div>
  );
}

export default App;
